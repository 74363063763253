<template>
  <div>

    <validation-observer
      ref="refFormObserver"
    >
      <!-- User Info: Input Fields -->
      <b-form>
        
        <!-- Licencia Administrativa -->
        <validation-provider
          #default="validationContext"
          name="Licencia Administrativa"
          rules="required"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Licencia Administrativa"
                label-for="admin_email"
              >
                <b-form-input
                  id="admin_email"
                  v-model="new_admin_email"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-button
                :disabled="new_admin_email && !validationContext.errors[0] ? false :true"
                :style="feedbackStyle($store.getters['app/currentBreakPoint'])"
                variant="primary"
                class="mb-1 mr-0 mr-sm-1"
                type="button"
                @click="submitAdminLicence"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                Update Admin Licence
              </b-button>
            </b-col>
          </b-row>
        </validation-provider>

        <!-- New Password -->
        <validation-provider
          v-if="recordData.admin"
          #default="validationContext"
          name="Password"
          rules="password"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="password">New Password</label>
                <b-input-group>
                  <b-form-input
                    id="password"
                    :type="passwordFieldType"
                    v-model="new_pass"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <div class="invalid-feedback mb-1" 
                :style="feedbackStyle($store.getters['app/currentBreakPoint'])">{{ validationContext.errors[0] }}</div>


            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-button
                :disabled="new_pass && !validationContext.errors[0] ? false :true"
                :style="feedbackStyle($store.getters['app/currentBreakPoint'])"
                variant="primary"
                class="mb-1 mr-0 mr-sm-1"
                type="button"
                @click="submitChanges"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                Change Password
              </b-button>
            </b-col>

          </b-row>
        </validation-provider>

         <!-- VALID_UNTIL -->
          <validation-provider
            v-if="recordData.admin"
            #default="validationContext"
            name="valid until"
            rules="required"
          >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Valid Until"
                label-for="valid_until"
                :state="getValidationState(validationContext)"
              >
              <b-form-datepicker
                id="valid_until"
                v-model="new_valid_until"
                class="mb-1"
                :min="minDate"
                :state="validateValidUntil"
              />
              <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
            </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-button
                :disabled="new_valid_until && !validationContext.errors[0] ? false :true"
                :style="feedbackStyle($store.getters['app/currentBreakPoint'])"
                variant="primary"
                class="mb-1 mr-0 mr-sm-1"
                type="button"
                @click="submitValidUntil"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                Update Admin License Validity Date
              </b-button>
            </b-col>
          </b-row>
          </validation-provider>

      </b-form>
    </validation-observer>

    <!-- Action Buttons -->
    <b-button
      variant="outline-secondary"
      type="button"
      @click="hasHistory() 
        ? $router.go(-1) 
        : $router.push({ name: 'school-view', params: { id: recordData.id } })"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('forms.go_back') }}
    </b-button>
  </div>
</template>

<script>
import {
  BInputGroup, BFormDatepicker, BInputGroupAppend, BButton, BRow, BCol, BFormGroup, BFormInvalidFeedback, 
  BFormInput, BForm, 
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BFormDatepicker,
    BInputGroup, BInputGroupAppend,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    recordData: {
      type: Object,
      required: true,
    },
    valid_until: {
      type: String,
      required: false
    }
  },
  mixins: [togglePasswordVisibility],
  data(){
    const now = new Date()
    const tomorrow = new Date(now)
    tomorrow.setDate(tomorrow.getDate() + 1)

    return {
      
      required,
      alphaNum,
      password,

      minDate: tomorrow,
      
      new_valid_until:null,
      new_pass:'',
      new_admin_email:'',
    }
  },
  computed: {
    validateValidUntil(){
      return this.new_valid_until ? true : false
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted(){
    if(this.recordData && this.recordData.user_admin_id){
      this.new_admin_email = this.recordData.admin.email
      this.new_valid_until = this.valid_until
    }
  },
  methods: {
    hasHistory () { 
       return window.history.length > 2 
    },
    feedbackStyle(bkpoint){
      return{
        'display': 'block',
        'margin-top': bkpoint === 'lg' || bkpoint === 'xl' || bkpoint === 'md' ? '21px' : '0px',
        'margin-bottom': bkpoint === 'sm' || bkpoint === 'xs' ? '21px' : '0px',
      }
    },
    async submitValidUntil(){
      let data = {
        tab: 'valid_until',
        id: this.recordData.id,
        valid_until: this.new_valid_until,
      }
      await this.$http.post('school/edit', data).then( response => {
        console.log(response)
        if(response.data.status == 200){
          this.$emit('refresh-data', response.data.school)
          this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        console.log("Err -> ", error);
      })
    },
    async submitAdminLicence(){
      let data = {
        tab: 'admin_licence_email',
        id: this.recordData.id,
        admin_email: this.new_admin_email,
      }
      await this.$http.post('school/edit', data).then( response => {
        console.log(response)
        if(response.data.status == 200){
          this.$emit('refresh-data', response.data.school)
          this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        console.log("Err -> ", error);
      })
    },
    async submitChanges(){
      console.log(this.recordData)
      let data = {
        tab: 'admin_licence_password',
        id: this.recordData.id,
        new_pass: this.new_pass,
      }
      await this.$http.post('school/edit', data).then( response => {
        console.log(response)
        if(response.data.status == 200){
          this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
          this.new_pass = '';
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        console.log("Err -> ", error);
      })

    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  },
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // form validation
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
</style>
