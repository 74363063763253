<template>
  <div>
    <custom-breadcrumb
          :pageTitle="$t('models.school')"
          :breadcrumb="[
            {
          text: $t('Schools'),
          to: 'schools'
        },
            {
              text: $t('forms.edit_model', {model: $t('models.school')}),
              active: true,
            },
          ]"
      ></custom-breadcrumb>
    <component :is="recordData === undefined ? 'div' : 'b-card'">
      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="recordData === undefined"
      >
        <h4 class="alert-heading">
          Error fetching school data
        </h4>
        <div class="alert-body">
          No user found with this id. Check
          <b-link
            class="alert-link"
            :to="{ name: 'schools'}"
          >
            School List
          </b-link>
          for other schools.
        </div>
      </b-alert>

      <b-tabs v-if="recordData">

        <!-- Tab: Logo -->
        <!-- <b-tab :active="logo_active">
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Logo</span>
          </template>
          <tab-logo
          v-if="logo_active"
            @refetch-data="getData(recordData.id)"
            :record-data="recordData"
            class="mt-2 pt-75"
          />
        </b-tab> -->


        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Account</span>
          </template>
          <tab-account
            v-if="!logo_active"
            :record-data="recordData"
            :countries_options="countries_options"
            :planOptions="planOptions"
            :special_needs_options="special_needs_options"
            @refetch-data="getData(recordData.id)"
            @toggle-logo-upload="toggleLogoUpload"
            class="mt-2 pt-75"
          />
          <tab-logo
            v-if="logo_active"
            @toggle-logo-upload="toggleLogoUpload"
            @refetch-data="getData(recordData.id)"
            :record-data="recordData"
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: Admin Licence & Password -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Admin Licence</span>
          </template>
          <edit-tab-admin-licence
          @refresh-data="refreshData"
            :record-data="recordData"
            :valid_until="valid_until"
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: Subscriptions -->
        <b-tab v-if="$can('manage', 'all')">
          <template #title>
            <feather-icon
              icon="Share2Icon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Subscriptions</span>
          </template>
          <edit-tab-subscriptions
            :record-data="recordData"
            :planOptions="planOptions"
            class="mt-2 pt-75"
            @refetch-data="getData(recordData.id)"
          />
  <!--           :blocked_payment_methods="blocked_payment_methods" -->

          

        </b-tab>
      </b-tabs>
    </component>
  </div>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import router from '@/router'
import TabAccount from './edit/TabAccount.vue'
import TabLogo from './edit/TabLogo.vue'
import EditTabAdminLicence from './edit/TabAdminLicence.vue'
import EditTabSubscriptions from './edit/TabSubscriptions.vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    TabLogo,
    TabAccount,
    EditTabAdminLicence,
    EditTabSubscriptions,
    CustomBreadcrumb,
  },
  data(){
    return {
      id: null,
      recordData: null,
      special_needs_options: [],
      countries_options: [],
      planOptions: [],
      langsOptions: [],

      logo_active: false,
      valid_until: null,
    }
  },
  mounted(){
    this.id =router.currentRoute.params.id;
    this.getData(this.id)
  },
  methods: {
    toggleLogoUpload(){
      this.logo_active = !this.logo_active
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    refreshData(school){
      this.getData(school.id)
    },
    async getData(id){
        console.log("loading")
        let data = {
          'id': id
        }
        await this.$http.post('fetchSchool', data).then( response =>{
            console.log(response.data)

            let recordData = response.data.school;

            //special_needs_selected
            if(recordData.special_needs_type){
              let arr = JSON.parse(recordData.special_needs_type)
              var unique_sn = arr.filter(this.onlyUnique);
              let result_sn = unique_sn.filter(function(x) {
                return x !== 0;
              });
              recordData.special_needs_selected = result_sn
            } else {
              recordData.special_needs_selected = []
            }
            
            //educational_stages_selected
            if(recordData.educational_stages){
              let arr_es = JSON.parse(recordData.educational_stages)
              var unique_es = arr_es.filter(this.onlyUnique);
              let result_es = unique_es.filter(function(x) {
                  return x !== '';
              });
              recordData.educational_stages_selected = result_es
            } else {
              recordData.educational_stages_selected = []
            }

            this.recordData = recordData
            

            //special_needs_options
            let special_needs = response.data.special_needs;
            let newSpecialNeedsOptions = []
            special_needs.forEach(x => {

              newSpecialNeedsOptions.push(
                  { 'value': String(x.id), 'label': this.$t('special_needs.'+x.name) }
              ) 
            
            });
            this.special_needs_options = newSpecialNeedsOptions;
            
            //PLANS
            let plans = response.data.plans;
            let newPlansOptions = []
            plans.forEach(x => {
              let can_select = true;
              if(x.canEdit==0){
                can_select = false;
              }
              newPlansOptions.push(
                  { 'value': x.id, 'label': x.description, 'can_select': can_select }
              ) 
            });
            this.planOptions = newPlansOptions;

            //Countries
            let countries = response.data.countries;
            let newCountriesOptions = []
            countries.forEach(x => {
              newCountriesOptions.push(
                  { 'value': x.code, 'label': x.name }
              ) 
            });
            this.countries_options = newCountriesOptions;


            let payment = response.data.payment
            if(payment){
              this.valid_until = payment.valid_until
            }

        }).catch(error => {
            console.log("Err -> ", error)
        })
    },
  }
}
</script>

<style>

</style>
